exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dot-slash-magic-js": () => import("./../../../src/pages/dot-slash-magic.js" /* webpackChunkName: "component---src-pages-dot-slash-magic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-salt-js": () => import("./../../../src/pages/salt.js" /* webpackChunkName: "component---src-pages-salt-js" */),
  "component---src-pages-secret-book-club-js": () => import("./../../../src/pages/secret-book-club.js" /* webpackChunkName: "component---src-pages-secret-book-club-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-tales-of-mischief-js": () => import("./../../../src/pages/tales-of-mischief.js" /* webpackChunkName: "component---src-pages-tales-of-mischief-js" */),
  "component---src-pages-thalassic-series-js": () => import("./../../../src/pages/thalassic-series.js" /* webpackChunkName: "component---src-pages-thalassic-series-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-ya-books-js": () => import("./../../../src/pages/ya-books.js" /* webpackChunkName: "component---src-pages-ya-books-js" */)
}

